( function( $ ) {
	'use strict';

	$( function() {
		// Get video from page content
		var $bannerVideo = $( '.js-banner-video' ).html();
		var video_element = '<video muted loop playsinline autoplay class="hero__background hero__background--video">' + $bannerVideo + '</video>';

		if( $bannerVideo !== undefined) {
			console.log( $bannerVideo );
			console.log( video_element );

			if (!$('body').hasClass( 'page-course-detail' )) {

				// Add video to header banner
				$('.page-banner').prepend( video_element );
			}
		}
	} );
} )( jQuery );
