( function( $ ) {
	'use strict';

	$( function() {
		var location = window.location;
		var pathname = location.pathname;
		var $menuItem = $( '.our-clients-menu__list__item__link' );

		// Set case study sidebar menu item as active if URL matches link
		$menuItem.each( function() {
			var href = $( this ).attr( 'href' );
			if ( pathname.indexOf( href ) > -1) {
				$( this ).addClass( 'our-clients-menu__list__item__link--active' );
			}
		} );
	} );
} )( jQuery );
