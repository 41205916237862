( function( $ ) {
	'use strict';

	$( function() {
		var tutorPicture = $j('.tutor-picture').find('img').outerHeight();

		if($j('.tutor-picture').find('img').outerWidth() > 300) {
			console.log('larger');
			$j('.tutor-picture').addClass('tutor-picture--lrg');
		}

		if($j('.tutor-connect').length > 0) {
			$j('.tutor-description').after($j('.tutor-connect'));
		}

		$j('.tutor-description').css('minHeight', $j('.tutor-picture').find('img').outerHeight());

		$j(window).resize(function() {
			$j('.tutor-description').css('minHeight', $j('.tutor-picture').find('img').outerHeight());
			tutorBioSpacing();
		});

		tutorBioSpacing();


		function tutorBioSpacing() {
			var resumeTitle = $j('.tutor-title').outerHeight();
			var resumeHeight = $j('.tutor-resume').outerHeight();
			var spacing = parseInt($j('.tutor-description').css('padding-top'));
			var bioHeight =  resumeTitle + resumeHeight + (spacing * 2) - 15;

			if(bioHeight < tutorPicture) {
				$j('.tutor-description').css('padding-bottom', '0');
			}
			else {
				$j('.tutor-description').css('padding-bottom', '');
			}
		}

	} );
} )( jQuery );
