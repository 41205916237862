( function( $ ) {
	'use strict';

	$( function() {
		var $corporateForm = $( '.js-corporate-enquiry-form' );
		var $typeSelector = $( '[id^=4]' );
		var quoteClass = 'content-form-corporate--quote';

		if ( $corporateForm.length > 0 ) {
			// On "I would like to" select change...
			$typeSelector.change(function() {
				// Add quote class if quote is selected, to show/hide relevant form fields
				var selectedOption = $typeSelector.find( 'option:selected' );
				if ( selectedOption.val() === 'Get a quote' ) {
					$corporateForm.addClass( quoteClass );
				} else {
					$corporateForm.removeClass( quoteClass );
				}
			});
		}
	} );
} )( jQuery );
