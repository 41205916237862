( function( $ ) {
	'use strict';

		(function (_this, $) {
			_this.tabAnimationDuration = 400;

			_this.initialise = function () {
			  $(function () {
				resizeTabs();
				bindTabClicks();
				bindTriggerClicks();
			  });

			  return _this;
			};

		var resizeTabs = function () {
			$(".tabsModule").each(function () {
			  var maxHeight = 0;

			  $(this)
				.find(".tab")
				.each(function () {
				  var numTabs = $(this).parent(".tabs").children(".tab").length;
				  var width = 100 / numTabs;
				  $(this).css("width", width + "%");

				  if ($(this).height() > maxHeight) {
					maxHeight = $(this).height();
				  }
				});

			  $(this)
				.find(".tab")
				.each(function () {
				  $(this).height(maxHeight);
				});
			});
		  };

		  var bindTabClicks = function () {
			$(".tabsModule .tabs .tab a").on("click", function (e) {
			  e.preventDefault();

			  if (!$(this).parents(".tab").hasClass("open")) {
				// Display correct contents
				var currentlyOpen =
				  "#" + $(this).parents(".tabs").find(".tab.open").attr("data-opens");
				var toOpen = "#" + $(this).parents(".tab").attr("data-opens");
				$(currentlyOpen)
				  .find(".content")
				  .slideUp(_this.tabAnimationDuration, function () {
					$(toOpen).find(".content").slideDown(_this.tabAnimationDuration);
				  });

				// Highlight new tab
				$(this).parents(".tabs").find(".tab").removeClass("open");
				$(this).parents(".tab").addClass("open");

				// Highlight correct trigger
				var targetItem = $(this).parents(".tab").attr("data-opens");
				$(this).parents(".tabsModule").find(".contentItem").removeClass("open");
				$("#" + targetItem).addClass("open");

				// Update the aria-selected attributes
				$(this).parents(".tabs").find(".tab").attr("aria-selected", "false");
				$(this).parents(".tab").attr("aria-selected", "true");
			  }
			});
		  };

		  var bindTriggerClicks = function () {
			$(".tabsModule .contentItem .trigger a").on("click", function (e) {
			  e.preventDefault();

			  if (!$(this).parents(".contentItem").hasClass("open")) {
				var contentItemID = $(this).parents(".contentItem").attr("id");

				// Display correct content
				$(this)
				  .parents(".contentItems")
				  .find(".contentItem.open .content")
				  .slideUp();
				$(this).parents(".contentItem").find(".content").slideDown();

				// Highlight new tab
				$(this).parents(".tabsModule").find(".tab").removeClass("open");
				$(this)
				  .parents(".tabsModule")
				  .find('.tab[data-opens="' + contentItemID + '"]')
				  .addClass("open");

				// Highlight correct trigger
				$(this)
				  .parents(".contentItems")
				  .find(".contentItem")
				  .removeClass("open");
				$(this).parents(".contentItem").addClass("open");
			  }
			});
		  };

		    // Initialise & assign to global scope
  window.TabsModule = _this.initialise();
})(window.TabsModule || {}, jQuery);
(function (_this, $) {
  _this.tabAnimationDuration = 400;
  _this.shownTagsOnMobile = 4;
	} );
} )( jQuery );
