( function( $ ) {
	'use strict';

	$( function() {
		var $content = $( '.page-content' );
		var $coursesList = $( '.available-courses-list' );
		var $cta = $( '.js-corp-cta' );

		// Hide courses list
		if ( $cta.length ) {
			$coursesList.hide();
		}

		// Move cta block to bottom of page
		$content.append( $cta );

	} );
} )( jQuery );
