( function( $ ) {
	'use strict';

	$( function() {
		var $trigger = $( '.js-show-all-courses' );
		var $coursesListWrap = $( '.js-courses-list-wrap' );
		var $showMore = $( '.js-courses-list-more' );
		var activeClass = 'courses-list-wrap--visible';

		if ( $showMore.length > 0 ) {
			// More courses are available, show all courses on button click then hide button
			$trigger.on( 'click', function( e ) {
				e.preventDefault();
				$coursesListWrap.addClass( activeClass );
				$trigger.hide();
			} );
		} else {
			// No more courses available, hide show all button
			$trigger.hide();
		}
	} );
} )( jQuery );
