( function( $ ) {
	'use strict';

	$( function() {

		// course count

		var $card = $( '.js-course-area-card');

		$card.each( function() {
			var $count = $( this ).find( '.js-course-count' );
			var $dropdown = $( this ).find( '.js-course-area-card-dropdown' );
			var course_count = $dropdown.find( 'ul.courseList' ).children('li').length;

			console.log( course_count );

			$count.text( course_count );
		} );

	} );
} )( jQuery );
