( function( $ ) {
	'use strict';

	$( function() {
		var $carousel = $( '.js-testimonials-carousel' );
		var $nav = $( '.js-testimonials-carousel-nav' );
		var actions = {
			prev: 'slickPrev',
			next: 'slickNext'
		};

		$carousel.slick( {
			arrows: false,
			infinite: true,
			slidesToShow: 1,
			adaptiveHeight: true
		} );

		$nav.on( 'click', function() {
			var direction = $( this ).data( 'dir' );
			var carouselName = $( this ).data( 'carousel' );
			$carousel.filter( "[data-carousel=\"".concat(carouselName, "\"]" )).slick(actions[direction]);
		} );
	} );
} )( jQuery );
