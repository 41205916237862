( function( $ ) {
	'use strict';

	$( function() {
		var $tutorsSection = $( '.js-subject-tutors' );
		var $pageContent = $( '.page-content' );

		// Move tutors section to end of page content
		if ( $tutorsSection.length > 0 ) {
			$pageContent.append( $tutorsSection );
		}

	} );
} )( jQuery );
