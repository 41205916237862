( function( $ ) {
	'use strict';

		(function (_this, $) {
			_this.initialise = function () {
			  $(function () {
				initialiseFormReset();
			  });
			  return _this;
			};
			var initialiseFormReset = function () {
			  $(".cancel").on("click", function () {
				var formID = $(".cancel").closest("form").attr("id");
				$("#" + formID).trigger("reset");
			  });
			};

			// Initialise & assign to global scope
			window.FormResetModule = _this.initialise();
		  })(window.FormResetModule || {}, jQuery);
		  (function (_this, $) {
			_this.initialise = function () {
			  $(function () {
				bindPlayButtons();
				bindProgressBarClick();
				bindTimeUpdate();
				initialiseDurations();
				bindPlaybackComplete();
			  });

			  return _this;
			};
		var bindPlayButtons = function () {
			$(".podcastModule .podcastControls .playButton").on("click", function (e) {
			  e.preventDefault();

			  var $podcast = $(this).closest(".podcast");
			  var audio = $podcast.find("audio")[0];

			  $podcast.toggleClass("playing");

			  if ($podcast.hasClass("playing")) {
				audio.play();
			  } else {
				audio.pause();
			  }
			});
		  };

		  var bindProgressBarClick = function () {
			$(".podcastModule .podcastControls .progressBar").on("click", function (e) {
			  var x = e.pageX - $(this).offset().left;
			  var width = $(this).width();
			  var fraction = x / width;

			  var audio = $(this).closest(".podcast").find("audio")[0];
			  audio.currentTime = audio.duration * fraction;
			});
		  };

		  var bindTimeUpdate = function () {
			$(".podcastModule audio").on("timeupdate", function () {
			  var currentTime = $(this)[0].currentTime;
			  var duration = $(this)[0].duration;
			  var percentageComplete = (currentTime / duration) * 100.0;

			  var $podcast = $(this).closest(".podcast");

			  var $progressBar = $podcast.find(".progressBarProgress");
			  $progressBar.css("width", percentageComplete + "%");

			  var $elapsedTime = $podcast.find(".podcastProgress .elapsedTime");
			  $elapsedTime.text(getTimeString(currentTime));
			});
		  };

		  var initialiseDurations = function () {
			$(".podcastModule audio").on("durationchange", function () {
			  var duration = $(this)[0].duration;
			  $(this)
				.closest(".podcast")
				.find(".podcastProgress .duration")
				.text(getTimeString(duration));
			});

			// IE fix
			setTimeout(function () {
			  $(".podcastModule audio").each(function () {
				var duration = $(this)[0].duration;
				$(this)
				  .closest(".podcast")
				  .find(".podcastProgress .duration")
				  .text(getTimeString(duration));
			  });
			}, 2000);
		  };

		  var getTimeString = function (secs) {
			var seconds = parseInt(secs);

			var hours = Math.floor(secs / (60 * 60));
			seconds = seconds - hours * (60 * 60);

			var minutes = Math.floor(seconds / 60);
			var seconds = seconds - minutes * 60;

			var timeString = "";

			if (hours > 0) {
			  timeString = timeString + hours + " hour ";
			}
			if (minutes > 0) {
			  timeString = timeString + minutes + " min ";
			}
			timeString = timeString + seconds + " sec";

			return timeString;
		  };

		  var bindPlaybackComplete = function () {
			$(".podcastModule audio").on("ended", function () {
			  $(this).closest(".podcast").removeClass("playing");
			  $(this)[0].pause();
			  $(this)[0].currentTime = 0;
			});
		  };

		  // Initialise & assign to global scope
		  window.PodcastModule = _this.initialise();
		})(window.PodcastModule || {}, jQuery);
		(function (root, $) {
		  var defaultOpts = {
			attribute: "data-autosubmit",
		  };
	} );
} )( jQuery );
