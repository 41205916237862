( function( $ ) {
	'use strict';

	$( function() {
		var $overviewSection = $( '.js-overview-section' );
		var $courseDownload = $( '.js-course-download' );

		// Move the course download to sit under overview
		$overviewSection.append($courseDownload);

	} );
} )( jQuery );
