( function( $ ) {
	'use strict';

	$( function() {
		var $close = $( '.js-notice-close' );
		var $notice = $( '.notice' );
		var $headerSearch = $( '.header-search' );
		var notice_height = $notice.outerHeight();

		console.log( notice_height );

		$close.on( 'click', function(e) {
			e.preventDefault();

			// Close notice
			$notice.hide();
			$( ':root' ).attr('style','--notice-height: 0px');
		} );


		// set css height variable
		$( ':root' ).attr('style','--notice-height:' + notice_height + 'px');
	} );
} )( jQuery );

jQuery(window).on('resize', function(){
	var notice_height = jQuery( '.notice' ).outerHeight();
	jQuery( ':root' ).attr('style','--notice-height:' + notice_height + 'px');
});
