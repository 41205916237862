( function( $ ) {
	'use strict';

	$( function() {
		var $class = $( '.js-class-item' );

		$class.each( function() {
			var time = $( this ).find( '.js-session-times' ).text();
			var startTime = '9am - 9:05am';
			time = time.trim();

			if ( time === startTime ) {
				$( this ).find( '.schedule__class__time' ).css( 'display', 'none' );
				$( this ).find( '.schedule__class__icon-text--duration' ).css( 'display', 'none' );
				$( '.js-class-summary-block').find( '.overview__cell--info' ).css( 'display', 'none' );

				if($( '.js-sessions' ).text() == '2 sessions, 0.2 hours total') {
					$( this ).find( '.schedule__class__icon-text' ).css( 'display', 'none' );
				}
			}

		} );

	} );
} )( jQuery );
