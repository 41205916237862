( function( $ ) {
	'use strict';

	$( function() {
		var $tabs = $( '.js-subject-tab' );
		var $tab2 = $( '.js-tab2' );
		var $backLinks = $( '.js-back' );
		var $images = $( '.tabbed-content__cell--images' );
		var $content = $( '.tabbed-content__cell--content-tab1' );
		var imagesInactiveClass = 'tabbed-content__cell--images--inactive';
		var contentInactiveClass = 'tabbed-content__content--inactive';
		var tabActiveClass = 'tabbed-content__courses-list__item__link--active';
		var subjectActiveClass = 'tabbed-content__subject--active';

		// Show/hide subject content
		$tabs.on( 'click', function(e) {
			e.preventDefault();

			// Get the tab ID that was clicked
			var tab = this.hash.substr(1);

			// Get the active tab and subject
			var $activeTab = $( '.' + tabActiveClass );
			var $activeSubject = $( '.' + subjectActiveClass );

			// Remove active classes from current active tab and subject
			$activeTab.removeClass(tabActiveClass);
			$activeSubject.removeClass(subjectActiveClass);

			// Add active classes to the clicked tab and subject
			$( this ).addClass( tabActiveClass );
			$( '[data-tab = ' + tab + ']' ).addClass( subjectActiveClass );

			// Add inactive classes to main images and content
			$images.addClass( imagesInactiveClass );
			$content.addClass( contentInactiveClass );
		} );

		function hideSubject(e) {
			e.preventDefault();

			// Get the active tab and subject
			var $activeTab = $( '.' + tabActiveClass );
			var $activeSubject = $( '.' + subjectActiveClass );

			// Remove active classes from subject
			$activeTab.removeClass(tabActiveClass);
			$activeSubject.removeClass(subjectActiveClass);

			// Remove inactive classes from main images and content
			$images.removeClass( imagesInactiveClass );
			$content.removeClass( contentInactiveClass );
		}

		$backLinks.on( 'click', hideSubject);
		$tab2.on( 'click', hideSubject);

	} );
} )( jQuery );
