( function( $ ) {
	'use strict';

	$( function() {
		$( 'body' ).on( 'click', '.js-courses-list-classes-trigger', function( e ) {
			e.preventDefault();

			$( this ).toggleClass( 'is-active' );
			$( this ).parents( '.js-courses-list-item' ).find( '.js-courses-list-classes-body' ).slideToggle( 400, function() {
				$( this ).toggleClass( 'is-active' );
			} );
		} );
	} );
} )( jQuery );
