( function( $ ) {
	'use strict';

	$( function() {
		function unique(array) {
			var result = [];

			$.each(array, function(i, e) {
				if($.inArray(e, result) == -1) result.push(e);
			});

			return result;
		}

		function compare(a, b) {
			if (a < b)
				return -1;
			if (a > b)
				return 1;
			return 0;
		}

		function condenseClassItem() {
			$('.js-course-item:not(.processed)').each(function() {

				var count = 0;
				var fastTrack = false;

				// create empty arrays
				var sessions = [];
				var locations = [];
				var tutors = [];
				var prices = [];
				var buttonLabel = "";
				var sameTutors = true;


				// loop through each class instance
				$(this).find('.js-class-item').each(function() {
					count++;

					sessions.push($(this).find('.js-sessions').text());
					// locations.push($(this).find('.js-class-location').html());
					prices.push($(this).find('.js-class-price .price').html());
					//tutors.push($(this).find('.js-class-tutor').html());
					$(this).find('.js-class-tutor').each(function() {
						tutors.push($(this).html());
					});
					buttonLabel = $(this).find('.enrolAction').html();

					// class notes
					if($(this).find('.class-note-important').length > 0) {
						var classNoteText = $(this).find('.class-note-important').text();

						if(classNoteText.indexOf('fast track') > 0) {
							$(this).find('.class-item__date span:last-child()').append('<span class="class-info__important">*</span>');
							fastTrack = true;
						}
					}

				});


				$(this).find('.js-class-location').each(function() {
					// create clone of element
					var clone = $(this).clone();

					// create anchor link
					var cloneLink = clone.find('a');
					var cloneLinkText = cloneLink.text();
					var cloneLinkTextTrimmed = $.trim(cloneLinkText); // remove empty spaces before/after
					var anchor = cloneLinkTextTrimmed.toLowerCase().replace(/\W+/g, '-'); // replace everything but letters and numbers with dashes
					anchor = anchor.replace(/-$/, ''); // remove trailing dash

					// add id to location
					$(this).attr('id', anchor);

					// replace clone link with anchor link
					cloneLink.attr('href', '#' + anchor);

					// add clone to unique locations
					locations.push(clone.html());
				});


				// find unique
				var uniqueSessions = unique(sessions);
				var uniqueLocations = unique(locations);
				var uniqueTutors = unique(tutors);
				var uniquePrices = unique(prices);

				// check if each class has the same set of tutors
				$(this).find('.js-class-item').each(function() {
					if (sameTutors) {
						var classTutors = $(this).find('.js-class-tutor').map(function() {return $(this).html()}).get()
						sameTutors = _.isEqual(uniqueTutors, classTutors)
					}
				});



				// append results
				$(this).find('.js-class-summary-sessions').html(uniqueSessions.join('<span class="class-summary-block__separator">or</span>'));
				$(this).find('.js-class-summary-locations').html(uniqueLocations.join('<span class="class-summary-block__separator">or</span>'));
				$(this).find('.js-class-summary-tutors').html(uniqueTutors.join('<span class="class-summary-block__separator">or</span>'));


				// check if there are multiple prices
				var hasPriceRange = false;

				if(uniquePrices.length > 1) {
					// multiple prices - order from low to high
					var orderedPrices = uniquePrices.sort(compare);
					$(this).find('.js-class-summary-price').html(orderedPrices[0] + ' - ' + orderedPrices[orderedPrices.length-1]);

					hasPriceRange = true;
				} else {
					// single price
					$(this).find('.js-class-summary-price').html(uniquePrices);

					hasPriceRange = false;
				}

				// determine class block styling
				//if(uniqueLocations.length === 1 && uniqueTutors.length === 1 && hasPriceRange === false) {
				if(uniqueLocations.length === 1 && sameTutors && hasPriceRange === false) {
					$(this).addClass('has-unique-details');
				}

				// fasttrack

				if(fastTrack) {
					$(this).find('.js-class-summary-block').after('<div class="class-summary-note"><strong>* Fast Track Course -</strong> Learning is accelerated with meetings twice weekly over four weeks</div>')
				}


				// change button copy if only location is self paced
				if(uniqueLocations.length === 1 && uniqueLocations[0].indexOf('Self-paced') > -1) {
					if($('.js-class-summary-sessions').text() == '2 sessions, 0.2 hours total') {
						$('.js-overview-button').text(buttonLabel);
					} else {
						$('.js-overview-button').text('Enrol now');
					}
				}


				if(count > 0) {
					$(this).find('.js-class-summary-block').css('display', '');
				} else {
					$(this).addClass('has-no-classes');
				}

				$(this).addClass('processed');
			});
		}

		setInterval(function() {
			condenseClassItem();
		}, 1000);
	} );
} )( jQuery );
