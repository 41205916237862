( function( $ ) {
	'use strict';

	$( function() {
		var $singleCourse = $( '.page-single-course' );
		var $pageTitle = $( '.page-content .js-page-title' );
		var $breadcrumbsPageTitle = $( '.js-breadcrumbs-page-title' );

		// Add page title to breadcrumbs trail
		if ( $singleCourse.length > 0 ) {
			$breadcrumbsPageTitle.text( $pageTitle.text() );
		}
	} );
} )( jQuery );
