( function( $ ) {
	'use strict';

	$( function() {
		var activeClass = 'sticky-header__bottom-section--is-active';
		var $trigger = $( '.js-sticky-header-menu-trigger' );
		var $menu = $( '.js-sticky-header-menu' );

		$trigger.on( 'click', function(e) {
			e.preventDefault();

			// Toggle menu
			$menu.toggleClass( activeClass );
		} );
	} );
} )( jQuery );
