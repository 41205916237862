( function( $ ) {
	'use strict';

	$( function() {
		const $class = $( '.js-class-sessions-block' );

		$class.each( function() {
			const $time = $( this ).find( '.js-session__time' );
			const startTime = new RegExp('9am\\s+-\\s+9:05am', 'gm');
			const endTime = new RegExp('4:55pm\\s+-\\s+5pm', 'gm');
			const endTimeAlt = new RegExp('5pm\\s+-\\s+5:05pm', 'gm');

			$time.each( function() {
				const timeValue = $( this ).text().trim();

				if ( (startTime.test(timeValue)) || (endTime.test(timeValue)) || (endTimeAlt.test(timeValue)) ) {
					$( this ).css( 'display', 'none' );
				}
			} );
		} );

	} );
} )( jQuery );
