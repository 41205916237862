( function( $ ) {
	'use strict';

	$( function() {
		var $locationsLink = $( '.js-locations-link' );
		var activeClass = 'below-search__locations__list-wrap--active';

		$locationsLink.on( 'click', function(e) {
			e.preventDefault();
			$( this ).parents( '.below-search' ).find( '.below-search__locations__list-wrap' ).toggleClass(activeClass);
		} );
	} );
} )( jQuery );
