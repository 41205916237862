( function( $ ) {
	'use strict';

	$( function() {
		var location = window.location;
		var pathname = location.pathname;
		var search = location.search;
		var $body = $( 'body' );

		// Single course page
		if (pathname.indexOf('course/') > -1) {
			$body.addClass('page-single-course js-header-banner-disabled js-content-menu-enabled');
		}

		// Course filter page
		if (pathname === '/courses' || pathname === '/courses/') {
			// Filters are added
			if (search.length) {
				$body.addClass('page-courses-filter-params');
			}
		}

		// Subject page
		if (pathname.indexOf('/courses/') !== -1) {
			var pathArray = pathname.split('/');

			if ( pathArray[2] ) {
				// Find subject page level class
				var levelClass = 'subject-page--top-level';
				if ( pathArray[3] ) {
					levelClass = 'subject-page--sub-level';
				}

				// Add what comes after /courses/ as a body class, and the level class
				$body.addClass( 'subject-page subject-page--' + pathArray[2] + ' ' + levelClass );
			}
		}

		// Specific standard pages
		if (pathname.indexOf('/legal') !== -1 || pathname.indexOf('/FAQs') !== -1 || pathname.indexOf('/discounts-and-course-funding') !== -1) {
			$body.addClass('js-content-menu-enabled');
		}
	} );
} )( jQuery );
