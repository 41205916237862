( function( $ ) {
	'use strict';

	$( function() {
		var $content = $( '.course-description' );
		var $list = $( '.course-description .courses-list-sub' );
		var $related = $( '.course-related' );
		var $accordion = $('.course-description .js-accordion');
		var $topicsBlock = $( '.course-description .available-courses-list' );
		var $accordionInTopicsBlock = $( '.course-description .available-courses-list .js-accordion' );
		var heading = 'What courses are you interested in?';
		var topicsCTAs = '<div class="topics__ctas">\n' +
			'\t\t\t\t\t\t\t\t\t<div class="l-grid l-grid--extra-padding l-grid--extra-padding--00">\n' +
			'\t\t\t\t\t\t\t\t\t\t<div class="l-grid__cell l-grid__cell--50@md l-grid__cell--extra-padding l-grid__cell--extra-padding--00 topics__ctas__cell">\n' +
			'\t\t\t\t\t\t\t\t\t\t\t<a href="/courses" class="topics__ctas__link">\n' +
			'\t\t\t\t\t\t\t\t\t\t\t\t<svg class="icon-search-lg topics__ctas__icon" role="img" focusable="false">\n' +
			'\t\t\t\t\t\t\t\t\t\t\t\t\t<use href="#svg-search-lg"/>\n' +
			'\t\t\t\t\t\t\t\t\t\t\t\t</svg>\n' +
			'\t\t\t\t\t\t\t\t\t\t\t\t<div class="topics__ctas__text">\n' +
			'\t\t\t\t\t\t\t\t\t\t\t\t\t<h6 class="topics__ctas__heading">Know what course you\'re looking for?</h6>\n' +
			'\t\t\t\t\t\t\t\t\t\t\t\t\t<p class="topics__ctas__para">Use our advanced course search</p>\n' +
			'\t\t\t\t\t\t\t\t\t\t\t\t</div>\n' +
			'\t\t\t\t\t\t\t\t\t\t\t</a>\n' +
			'\t\t\t\t\t\t\t\t\t\t</div>\n' +
			'\t\t\t\t\t\t\t\t\t\t<div class="l-grid__cell l-grid__cell--50@md l-grid__cell--extra-padding l-grid__cell--extra-padding--00 topics__ctas__cell">\n' +
			'\t\t\t\t\t\t\t\t\t\t\t<a href="#subscribe" class="topics__ctas__link">\n' +
			'\t\t\t\t\t\t\t\t\t\t\t\t<svg class="icon-phone-email topics__ctas__icon" role="img" focusable="false">\n' +
			'\t\t\t\t\t\t\t\t\t\t\t\t\t<use href="#svg-phone-email"/>\n' +
			'\t\t\t\t\t\t\t\t\t\t\t\t</svg>\n' +
			'\t\t\t\t\t\t\t\t\t\t\t\t<div class="topics__ctas__text">\n' +
			'\t\t\t\t\t\t\t\t\t\t\t\t\t<h6 class="topics__ctas__heading">Subscribe for the latest course news</h6>\n' +
			'\t\t\t\t\t\t\t\t\t\t\t\t\t<p class="topics__ctas__para">Don’t miss a thing, subscribe today</p>\n' +
			'\t\t\t\t\t\t\t\t\t\t\t\t</div>\n' +
			'\t\t\t\t\t\t\t\t\t\t\t</a>\n' +
			'\t\t\t\t\t\t\t\t\t\t</div>\n' +
			'\t\t\t\t\t\t\t\t\t</div>\n' +
			'\t\t\t\t\t\t\t\t</div>';

		if ($topicsBlock.length === 0) {
			// Available courses block doesn't exist, get tag group block instead
			$topicsBlock = $('.course-description [class^="taggroup-"]');
		}

		// Change topics block if courses list exists
		if ( $list.length > 0 ) {

			// Accordion is already in topics block
			if ($accordionInTopicsBlock.length > 0) {
				// Add CTAs to block
				$accordion.after(topicsCTAs);

			} else { // Accordion is not in topics block (or doesn't exist)
				$list.each(function() {

					// This list isn't inside an accordion
					if ($( this ).parents('.js-accordion-panel').length === 0) {

						// Change heading if it's a course list
						if ($( this ).parents('.available-courses-list').length > 0) {
							heading = 'Available courses';
						}

						// Add heading and CTAs
						$( this ).before('<h3 class="topics__heading">' + heading + '</h3>');
						$( this ).after(topicsCTAs);

						// Move related subjects into topics block
						if ( $related.length > 0 ) {
							$(this).after($related);
						}

						// Move accordion into topics block
						if ( $accordion.length > 0 ) {
							var $accordionHeading = $accordion.prev('h2');
							$accordionHeading.addClass( 'subject-accordion-heading' );

							$(this).after($accordionHeading);
							$accordionHeading.after($accordion);
						}
					}
				});
			}

			// Move topics block to top of page
			$content.prepend($topicsBlock);
		}
	} );
} )( jQuery );
