( function( $ ) {
	'use strict';

	$( function() {
		var $overviewSection = $( '.js-overview-section' );
		var $courseTagline = $( '.page-content .course-tagline' );

		// Move the overview/summary to sit after the tagline
		$overviewSection.insertAfter($courseTagline);
	} );
} )( jQuery );
