( function( $ ) {
	'use strict';

	$( function() {
		var activeClass = 'site-offcanvas--is-active';

		var $trigger = $( '.js-site-offcanvas-trigger' );
		var $body = $( '.js-site-offcanvas' );
		var $close = $( '.js-site-offcanvas-close' );

		$trigger.on( 'click', function() {
			$body.addClass( activeClass );
		} );

		$close.on( 'click', function() {
			$body.removeClass( activeClass );
		} );
	} );
} )( jQuery );
