(function ($) {
	'use strict';

	$(function () {
		$(document).on('click', 'a[href*="#"]', function(event) {
			// On-page links
			if ( location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname ) {
				// Figure out element to scroll to
				var target = $( this.hash );
				target = target.length ? target : $( '[name=' + this.hash.slice(1) + ']' ); // Does a scroll target exist?

				// Get sticky header height to detract from scroll
				var $stickyHeader = $( '.js-sticky-header' );
				var stickyHeaderHeight = $stickyHeader.length ? $stickyHeader.height() : 0;

				if ( target.length ) {
					// Only prevent default if animation is actually gonna happen
					event.preventDefault();
					$( 'html, body' ).animate({
						scrollTop: target.offset().top - stickyHeaderHeight
					}, 1000, function () {
						// Callback after animation
						// Must change focus!
						var $target = $( target );
						$target.focus();

						if ( $target.is( ":focus" ) ) {
							// Checking if the target was focused
							return false;
						} else {
							$target.attr( 'tabindex', '-1' ); // Adding tabindex for elements not focusable

							$target.focus(); // Set focus again
						}
					});
				}
			}
		});
	});
})( jQuery );
