( function( $ ) {
	'use strict';

	$( function() {
		var $articleTitle = $( '.js-article-title' );
		var $pageTitle = $( '.js-page-title' );
		var $pageHeading = $( '.page-content h1:first' );

		var $breadcrumbsBar = $( '.breadcrumbs-bar' );
		var $pageContent = $( '.page-content' );

		var $headerBannerDisabled = $( '.js-header-banner-disabled' );

		var $courseFilter = $( '.page-courses-filter' );
		var $singleTutor = $( '.page-tutor' );
		var $singleLocation = $( '.page-location' );
		var $singleRoom = $( '.page-room' );
		var $singleClass = $( '.page-class-detail' );
		var $singleProduct = $( '.page-single-product' );
		var $productList = $( '.page-products-list' );
		var $customisableTemplate = $( '.page-customisable' );
		var $corpClass = $( '.page-class-detail-corp' );

		var $sidebarTitleSection = $( '.title-section--mobile-only' );
		var tag = 'Course_';
		var $tagline = $( '.course-tagline' );
		var $pageHeadingClone = $pageHeading.clone();
		var $taglineClone = $tagline.clone();

		var coursesHeading = '<h1 class="h2 title-section__heading title-section__heading--top">Courses</h1>';
		var searchResultsText = '<h2 class="course-tagline"><strong>Search results.</strong> Here\'s what we found.</h2><p>Your search results are listed below. If you need help finding something that\'s right for you, please <a href="/contact">contact us</a>.</p>';

		if ( $articleTitle.length > 0 ) {
			// Page is blog article, add article title to page title
			$pageTitle.html($articleTitle.html());
			$articleTitle.remove();
			$pageHeading.first().remove();

		} else if ( $pageHeading.length > 0 ) {
			// Add page title to image banner, unless banner is disabled or this is the courses filter page
			if ( $headerBannerDisabled.length === 0 && $courseFilter.length === 0 ) {
				$pageTitle.html($pageHeading.first().html());
				$pageHeading.first().remove();

			}

			if ( $headerBannerDisabled.length > 0 ) {
				// Page banner is disabled (and page is single course/tutor/location/class)

				// Remove banner to remove the empty <h1>
				$( '.page-banner' ).remove();

				if ( $singleTutor.length > 0 ) {
					// Page is single tutor
					tag = 'People_';
				}
				if ( $singleLocation.length > 0 ) {
					// Page is single location
					tag = 'Site_';
				}
				if ( $singleRoom.length > 0 ) {
					// Page is single room
					tag = 'Room_';
				}
				if ( $singleClass.length > 0 ) {
					// Page is single class
					tag = 'Class_';
				}
				if ( $singleProduct.length > 0 ) {
					// Page is single product
					tag = 'Product_';
				}

				// Add tag above page heading
				$pageHeading.before( '<p class="tag--large title-section__tag">' + tag + '</p>' );

				// Replace content in mobile only sidebar title section
				$sidebarTitleSection.empty();
				$sidebarTitleSection.append( '<p class="tag--large title-section__tag">' + tag + '</p>' );
				$sidebarTitleSection.append( $pageHeadingClone );
				$sidebarTitleSection.append( $taglineClone );

			}

			if ( $courseFilter.length > 0 ) {
				// Page is courses filter, add courses title and remove existing H1
				if ( $breadcrumbsBar.length > 0 ) {
					$breadcrumbsBar.after( coursesHeading );
					$pageHeading.first().remove();
				}
			}

		} else if ( $productList.length > 0 ) {
			// Page is products
			$pageTitle.html('Products');

		} else if ( $customisableTemplate.length === 0 ) {
			// Page is courses and not customisable template, add title to page content
			$pageContent.prepend( coursesHeading );

			if ( $breadcrumbsBar.length > 0 ) {
				// Add text to top of page
				$breadcrumbsBar.after( searchResultsText );

				// Add text to sidebar for mobile
				$sidebarTitleSection.empty();
				$sidebarTitleSection.append( coursesHeading );
				$sidebarTitleSection.append( searchResultsText );
			}
		}

		// If corporate class page, change page title to the first H1 in description
		if ( $corpClass.length > 0 ) {
			var $classHeading = $( '.classDescription h1:first' );

			if ( $classHeading.length > 0 ) {
				$pageTitle.html($classHeading.html());
			}
		}
	} );
} )( jQuery );