( function( $ ) {
	'use strict';

	$( function() {
		var $backToTop = $( '.js-back-to-top' );
		var activeClass = 'back-to-top--active';

		$( window ).scroll(function() {
			if ( $( window ).scrollTop() > 300) {
				$backToTop.addClass( activeClass );
			} else {
				$backToTop.removeClass( activeClass );
			}
		});
	} );
} )( jQuery );
