( function( $ ) {
	'use strict';

	$( function() {
		var $trigger = $( '.js-header-search-trigger' );
		var $close = $( '.js-header-search-close' );
		var $headerSearch = $( '.header-search' );
		var $notice = $( '.notice' );
		var noticeHiddenClass = 'notice--hidden';

		// Open header search
		$trigger.on( 'click', function(e) {
			e.preventDefault();
			$headerSearch.fadeIn();
			$notice.addClass( noticeHiddenClass );
		} );

		// Close header search
		$close.on( 'click', function(e) {
			e.preventDefault();
			$headerSearch.fadeOut();
			$notice.removeClass( noticeHiddenClass );
		} );
	} );
} )( jQuery );
