( function( $ ) {
	'use strict';

	$( function() {
		$(".jsCustomSelect").on("click", function () {
			var $customSelect = $(this);

			$customSelect.toggleClass("active");
		});

		$(".jsCustomSelectOption").on("click", function () {
			var $customSelectOption = $(this);
			var $customSelect = $customSelectOption.closest(".jsCustomSelect");

			var $customSelectSelection = $customSelect.find(".jsCustomSelectSelection");
			var customSelectValue = $customSelectOption.data("value");
			var customSelectOptionName = $customSelectOption.text();

			$customSelect.find('.jsCustomSelectOption').removeClass('selected');

			$customSelectOption.addClass('selected');
			$customSelect.find("input").val(customSelectValue);
			$customSelectSelection.text(customSelectOptionName);

			if(customSelectValue=='All events'){
				$customSelect.parents().closest('.events-list').find('.events-list__date-list').fadeIn();
			}else{
				$customSelect.parents().closest('.events-list').find('.events-list__date-list').fadeOut();
				$customSelect.parents().closest('.events-list').find(`.events-list__date-list[data-option='${customSelectValue}']`).fadeIn();
			}

		});
	} );
} )( jQuery );
