( function( $ ) {
	'use strict';

	$( function() {
		var $metaTitle = $( '.js-course-meta-title' );
		var $metaDescription = $( '.js-course-meta-description' );

		// Change document title
		if ( $metaTitle.length > 0 ) {
			document.title = $metaTitle.text();
		}

		// Change meta description
		if ( $metaDescription.length > 0 ) {
			$( 'meta[name="description"]' ).attr( 'content', $metaDescription.text() );
		}
	} );
} )( jQuery );
