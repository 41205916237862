( function( $ ) {
	'use strict';

	$( function() {
		var $carousel = $( '.js-clients-carousel' );
		var $nav = $( '.js-clients-carousel-nav' );
		var actions = {
			prev: 'slickPrev',
			next: 'slickNext'
		};

		$carousel.slick( {
			arrows: false,
			infinite: true,
			slidesToShow: 6,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4
					}
				},
				{
					breakpoint: 800,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				}
			]
		} );

		$nav.on( 'click', function() {
			var direction = $( this ).data( 'dir' );
			var carouselName = $( this ).data( 'carousel' );
			$carousel.filter( "[data-carousel=\"".concat(carouselName, "\"]" )).slick(actions[direction]);
		} );
	} );
} )( jQuery );
