( function( $ ) {
	'use strict';

	$( function() {
		var $pageTagline = $( '.page-tagline' );
		var $courseTagline = $( '.course-tagline' );
		var $taglineContainer = $( '.js-tagline' );
		var $pageTitle = $( '.js-page-title' );
		var $singleProduct = $( '.page-single-product' );
		var $headerBannerDisabled = $( '.js-header-banner-disabled' );

		// Regular page: add whole tagline to header
		if ( $pageTagline.length > 0 ) {
			$taglineContainer.html($pageTagline.html());
			$pageTagline.first().remove();
		}

		// Courses pages: remove <strong> text from tagline, then add it to header
		if ( $courseTagline.length > 0 ) {
			// Remove the first part of the tagline, unless it's the courses page or single product page
			if ($pageTitle.html() !== 'Courses' && $singleProduct.length === 0) {
				$courseTagline.find( 'strong' ).remove();
			}

			// Add tagline to header banner, unless it's disabled
			if ( $headerBannerDisabled.length === 0 ) {
				$taglineContainer.html($courseTagline.html());
				$courseTagline.first().remove();
			}
		}
	} );
} )( jQuery );
