( function( $ ) {
	'use strict';

	$( function() {
		var $tabs = $( '.js-tab' );
		var tabActiveClass = 'tabbed-content__tab--active';
		var contentActiveClass = 'tabbed-content__content--active';

		$tabs.on( 'click', function(e) {
			e.preventDefault();

			// Get the tab ID that was clicked
			var tab = this.hash.substr(1);

			// Get the active tab and content
			var $activeTab = $( '.' + tabActiveClass );
			var $activeContent = $( '.' + contentActiveClass );

			// Remove active classes from current active tab and content
			$activeTab.removeClass(tabActiveClass);
			$activeContent.removeClass(contentActiveClass);

			// Add active classes to the clicked tab and content
			$( this ).addClass( tabActiveClass );
			$( '[data-tab = ' + tab + ']' ).addClass( contentActiveClass );

		} );
	} );
} )( jQuery );
