( function( $ ) {
	'use strict';

	$( function() {
		var $subMenus = $( '.primary-menu__sub-menu' );
		var $menuItem = $( '.primary-menu__item' );
		var childClass = 'hasChildren js-sub-menu-trigger' ;

		$subMenus.each( function() {
			if ( $( this ).find( 'li' ).length > 0 ) {
				$( this ).closest( $menuItem ).addClass( childClass );
			}
		});


		// Primary menu sub navigation flyouts

		var $body = $( 'body' );
		var $trigger = $( '.primary-menu__list' ).children( '.primary-menu__item' ).children( '.primary-menu__link' );
		var $parent = '';
		var active_class = 'is-active';
		var $sub_menus = $( '.primary-menu__list > .primary-menu__item > .js-primary-sub-menu' );
		var $drawer = $( '.js-primary-menu-drawer' );
		var $teritary_drawer = $( '.js-primary-teritary-menu-drawer' );


		var $sub_menu_item = $( '.primary-menu__sub-menu' ).children('.primary-menu__item' ).children( '.primary-menu__link' );
		var $teritary_drawer = $( '.js-primary-sub-menu .js-primary-sub-menu' );

		menu_positioning();

		$trigger.on( 'click', function( e ) {
			$parent = $( this ).parents( '.js-menu-parent' );
			$sub_menus = $parent.find( '.primary-menu__list > .primary-menu__item > .js-primary-sub-menu' );
			$drawer = $parent.find( '.js-primary-menu-drawer' );
			$teritary_drawer = $parent.find( '.js-primary-teritary-menu-drawer' );

			$sub_menus.removeClass( active_class );
			$teritary_drawer.removeClass( active_class );
			$trigger.parent().removeClass( active_class );

			if ( $( this ).parent().hasClass( 'js-sub-menu-trigger' ) ) {
				e.preventDefault();

				$drawer.addClass( active_class );
				$body.addClass( 'menu-drawer-open' );
				$( this ).parent().find( '.js-primary-sub-menu' ).addClass( active_class );
				$( this ).parent().addClass( active_class );
				$body.css( 'overflow-y', 'hidden' );

			} else  {
				$drawer.removeClass( active_class );
				$body.removeClass( 'menu-drawer-open' );
				$body.css( 'overflow-y', 'visible' );
			}
		} );

		$sub_menu_item.on( 'click', function( e ) {
			$parent = $( this ).parents( '.js-menu-parent' );
			$drawer = $parent.find( '.js-primary-menu-drawer' );
			$teritary_drawer = $parent.find( '.js-primary-teritary-menu-drawer' );

			console.log( $parent );
			console.log( $teritary_drawer );

			if ( $( this ).parent().hasClass( 'js-sub-menu-trigger' ) ) {
				e.preventDefault();
				var tertiary_menu_items = $( this ).parent().find( '.primary-menu__sub-menu' );

				$teritary_drawer.addClass( active_class );
				$( '.js-teritary-menu-holder').html( tertiary_menu_items.html() );
			} else  {
				$teritary_drawer.removeClass( active_class );
				$drawer.removeClass( active_class );
				$body.removeClass( 'menu-drawer-open' );
				$body.css( 'overflow-y', 'visible' );
				$( '.js-primary-sub-menu' ).removeClass( active_class );
			}
		} );

		$('.js-teritary-menu-holder').on("click", ".primary-menu__link", function(e) {

				$teritary_drawer.removeClass( active_class );
				$drawer.removeClass( active_class );
				$body.removeClass( 'menu-drawer-open' );
				$body.css( 'overflow-y', 'visible' );
				$( '.js-primary-sub-menu' ).removeClass( active_class );
		} )


		// close button

		var $close_button = $( '.js-menu-drawer-close' );

		$close_button.on( 'click', function( e ) {
			$drawer.removeClass( active_class );
			$teritary_drawer.removeClass( active_class );
			$sub_menus.removeClass( active_class );
			$body.removeClass( 'menu-drawer-open' );
			$body.css( 'overflow-y', 'visible' );
			$( '.js-sub-menu-trigger' ).removeClass( active_class );
		} );

	} );

	function menu_positioning() {
		var header_pos = $( '.site-header').outerHeight();
		var notice_height = $( '.notice' ).outerHeight();
		var $sub_menus = $( '.primary-menu__list > .primary-menu__item > .js-primary-sub-menu' );
		var $drawer = $( '.js-primary-menu-drawer' );
		var $teritary_drawer = $( '.js-primary-teritary-menu-drawer' );

		$drawer.css( 'top', 'calc(' + header_pos + 'px + var(--notice-height))' );
		$teritary_drawer.css( 'top', 'calc(' + header_pos + 'px + var(--notice-height))' );
		$sub_menus.css( 'top', 'calc(' + header_pos + 'px + var(--notice-height) + 5rem)' );
	}

	$(window).on('resize', function () {
		menu_positioning();
	});

} )( jQuery );
