( function( $ ) {
	'use strict';

	$( function() {
		var $featuredArticles = $( '.js-featured-articles' );
		var $subscribe = $( '#subscribe' );

		// Move featured articles section to above subscribe block
		if ( $featuredArticles.length > 0 ) {
			$subscribe.before( $featuredArticles );
		}

	} );
} )( jQuery );
