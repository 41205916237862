( function( $, window ) {
	'use strict';

	$( function() {
		var interval = 250;
		var visibleHeight = 260;
		var activeClass = 'sticky-header--is-active';
		var $header = $( '.js-sticky-header' );
		var didScroll = false;
		var $body = $( 'body' );
		var bodyActiveClass = 'sticky-header-active';

		$( window ).on( 'scroll', function() {
			didScroll = true;
		} );

		setInterval( function() {
			if ( didScroll ) {
				handleScroll();
				didScroll = false;
			}
		}, interval );

		var handleScroll = function handleScroll() {
			var currentScrollTop = ( $( window ).scrollTop() || $( 'body' ).scrollTop() );

			if ( currentScrollTop > visibleHeight ) {
				if ( ! $header.hasClass( activeClass ) ) {
					$header.addClass( activeClass );
					$body.addClass( bodyActiveClass );
				}
			} else {
				if ( $header.hasClass( activeClass ) ) {
					$header.removeClass( activeClass );
					$body.removeClass( bodyActiveClass );
				}
			}
		};

		$( window ).trigger( 'scroll' );
	} );
} )( jQuery, window );
