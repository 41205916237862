( function( $ ) {
	'use strict';

	$( function() {
		var $printDate = $( '.js-print-date' );

		if ( $printDate.length > 0 ) {
			var date = new Date().toLocaleDateString(undefined, {timeZone: "Australia/Sydney"});
			$printDate.html(date);
		}
	} );
} )( jQuery );
