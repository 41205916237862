( function( $ ) {
	'use strict';

	$( function() {
		var $trigger = $( '.js-accordion-trigger' );
		var $panel = $( '.js-accordion-panel' );

		$trigger.on( 'click', function() {
			$( this ).toggleClass( 'is-active' );
			$( this ).next( $panel ).slideToggle( 400, function() {
				$( this ).toggleClass( 'is-active' );
			} );
		} );
	} );
} )( jQuery );
