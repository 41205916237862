( function( $ ) {
	'use strict';

	$( function() {
		var currentPathname = window.location.pathname.toLowerCase();
		var currentPathnameQuery = window.location.search;
		var pageTitle = $( '.page-title' );
		var courseDescription = $( '.course-description' );

		if (currentPathname === '/courses' && currentPathnameQuery === "") {
			// pageTitle.text('Courses in Sydney');
			courseDescription.addClass('no-breadcrumbs');
			courseDescription.html('<p class="lead">Choose from hundreds of exciting courses in Sydney at the Centre for Continuing Education, all open to the general public. With classes that cover a diverse range of interests and designed for all stages of life, you can learn new skills, gain new insights or discover your untapped creativity.</p>')
		}

		else if (currentPathname.indexOf('waitinglistform') > 0) {
			pageTitle.text('Waiting List');
		}
		else if (currentPathname.indexOf('products') > 0) {
			pageTitle.text('Products');
		}
		else if (currentPathname.indexOf('checkout') > 0) {
			pageTitle.text('Checkout');
		}
	} );
} )( jQuery );
