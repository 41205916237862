( function( $ ) {
	'use strict';

	$( function() {
		var $form = $( '.js-subscribe-form' );
		var $additionalFields = $( '.subscribe__form__additional-fields' );
		var $activeClass = 'subscribe__form__additional-fields--active';

		$form.on( 'click', function() {
			// Add active class to additional fields if it doesn't already have it
			if ($( $additionalFields ).hasClass( $activeClass ) === false) {
				$additionalFields.addClass( $activeClass );
			}
		} );
	} );
} )( jQuery );
