( function( $ ) {
	'use strict';

	$( function() {
		var activeClass = 'is-active';
		var offcanvasActiveClass = 'site-offcanvas--is-active';

		// var $item = $( '.js-offcanvas-menu-item' );
		// var $trigger = $( '.js-offcanvas-menu-trigger' );
		// var $submenu = $( '.js-offcanvas-menu-submenu' );
		var $item = $( '.offcanvas-menu--primary .primary-menu__item.hasChildren' );
		var $trigger = $( '.offcanvas-menu--primary .primary-menu__item.hasChildren > .primary-menu__link' );
		var $submenu = $( '.offcanvas-menu--primary .primary-menu__item.hasChildren > .primary-menu__sub-menu-wrap' );
		var $back = $( '.js-site-offcanvas-back' );
		var $offcanvas = $( '.js-site-offcanvas' );

		$back.removeClass( activeClass );

		// Add heading to each submenu
		$submenu.each( function() {
			var item = $( this ).closest( $item ).find( $trigger ).first();
			var title = item.text().trim();
			var link = item.attr( 'href' );
			$( this ).prepend( '<div class="offcanvas-menu-submenu__title js-offcanvas-menu-submenu-title"><a href="' + link + '">' + title + '</a></div>' );
		} );

		$trigger.on( 'click', function( event ) {
			event.preventDefault();
			$( this ).parent().find( $submenu ).first().addClass( activeClass );
			$back.addClass( activeClass );
		} );

		$back.on( 'click', function() {
			$submenu.filter( ".".concat(activeClass) ).last().removeClass( activeClass );

			if ( $submenu.filter( ".".concat(activeClass) ).length === 0 ) {
				$back.removeClass( activeClass );
			}
		} );

		$( $submenu ).find( 'a' ).not( $trigger ).on( 'click', function() {
			$offcanvas.removeClass( offcanvasActiveClass );
		} );
	} );
} )( jQuery );
