( function( $ ) {
	'use strict';

	$( function() {
		// Get image from page content
		var $bannerSrc = $( '.page-banner-img' ).prop('src');

		if( $bannerSrc !== undefined) {

			if (!$('body').hasClass( 'page-course-detail' )) {

				// Add as background image in header banner
				if( $bannerSrc.indexOf("blank") < 0) {
					$('.page-banner').css({
						backgroundImage : 'url(' + $bannerSrc + ')'
					});
				}
			}
		}
	} );
} )( jQuery );
