( function( $ ) {
	'use strict';

	$( function() {
        var $section            = $( '.js-image-card-carousel' );
        var $sectionImage       = $( '.js-image-card-carousel-image' );
        var $carousel           = $( '.js-image-card-slider' );
        var $prevSlide          = $( '.js-image-card-slider-prev' );
        var $nextSlide          = $( '.js-image-card-slider-next' );
        var $slideTitle         = $( '.js-image-card-slider-title' );
        var $slide              = $( '.js-image-card-slider-card' );

        if ( $section.length > 0 ) {
            $carousel.slick( {
                rows: 0,
                dots: true,
                arrows: false,
                infinite: false,
                slidesToShow: 2,
                appendDots: $( '.js-image-card-carousel-dots' ),
                responsive: [
                    {
                        breakpoint: 1400,
                        settings: {
                            slidesToShow: 1,
                        }
                    },

                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            infinite: true,
                        }
                    }
                ]
            } );

            $prevSlide.on( 'click', function() {
                $carousel.slick( 'slickPrev' );
            } );

            $nextSlide.on( 'click', function() {
                $carousel.slick( 'slickNext' );
            } );

            function updateSlideContent() {
                var $currentIndex = $carousel.slick( 'slickCurrentSlide' );
                var $currentSlide = $carousel.find( $slide ).eq( $currentIndex );

                if ( $currentSlide.length > 0 ) {
                    var $backgroundUrl  = $currentSlide.css( 'background-image' );
                    var $titleText      = $currentSlide.find( '.js-image-card-slider-card-title' ).text();

                    var urlRegex    = /url\("(.+)"\)/;
                    var matches     = $backgroundUrl.match( urlRegex );

                    if ( matches && matches.length > 1 ) {
                        $backgroundUrl = matches[1];
                    }

                    // Update the section title with the title of the current slide
                    $slideTitle.text( $titleText );
                }
            }

			updateSlideContent();

            $carousel.on( 'afterChange', function() {
				updateSlideContent();
            } );
        }
	} );
} )( jQuery );
