( function( $ ) {
	'use strict';

	$( function() {
		var $changeCartButtonTextInterval = setInterval(function() {
			var $shortlistLinkEnrol = $j('#shortlist .shortlistLinkEnrol');
			if(!$shortlistLinkEnrol.hasClass('text-changed')) {
				$shortlistLinkEnrol.addClass('text-changed');
				$shortlistLinkEnrol.text('Checkout / Enrol');
			}

			var confirmOrderDialog = $j('.classAction >div').closest('.confirmOrderDialog');

			if(confirmOrderDialog.length > 0) {
				cartAlertBoxOpen(confirmOrderDialog.parents('.class-block-container'));
			} else {
				cartAlertBoxClose($j('.class-block-container'));
			}

			$j('.class-block-container').each(function() {
				var buttonText = $j(this).find('.enrolAction').text();
				// console.log(buttonText);

				if( buttonText === 'Enrol Now' ) {

					$j(this).find('.enrolAction').text('Enrol now');
				}
			});

		}, 1000);


		function cartAlertBoxOpen($parent) {
			$parent.find('.cart-alert-box').addClass('is-active');
			$parent.find('.cart-alert-box').animate({opacity: 1});
		}

		function cartAlertBoxClose($parent) {
			$parent.find('.cart-alert-box').removeClass('is-active');
			$parent.find('.cart-alert-box').animate({opacity: 0});
		}
	} );
} )( jQuery );
