( function( $ ) {
	'use strict';

	$( function() {
		$j('.close-help-btn').click(function() {
			sessionStorage.setItem("helpdesk", "false");
			$j('.close-help-btn').hide();
			$j('#launcher').hide();

		});

		helpdesk(sessionStorage.getItem("helpdesk"));

		function helpdesk(show) {
			if (show !== "false") {
				window.setTimeout(function(){
					window.zESettings = {
						webWidget: {
							chat: {
								connectOnPageLoad: false
							}
						}
					};
					window.zEmbed || function (e, t) {
						var n, o, d, i, s, a = [],
							r = document.createElement("iframe");
						window.zEmbed = function () {
							a.push(arguments);
						}, window.zE = window.zE || window.zEmbed, r.src = "javascript:false", r.title = "", r.role = "presentation", (r.frameElement || r).style.cssText = "display: none", d = document.getElementsByTagName("script"), d = d[d.length - 1], d.parentNode.insertBefore(r, d), i = r.contentWindow, s = i.document;
						try {
							o = s;
						} catch (c) {
							n = document.domain, r.src = 'javascript:var d=document.open();d.domain="' + n + '";void(0);', o = s;
						}
						o.open()._l = function () {
							var o = this.createElement("script");
							n && (this.domain = n), o.id = "js-iframe-async", o.src = e, this.t = +new Date, this.zendeskHost = t, this.zEQueue = a, this.body.appendChild(o);
						}, o.write('<body onload="document._l();">'), o.close();
					}("//assets.zendesk.com/embeddable_framework/main.js", "cce.zendesk.com"); /*]]>*/
				}, 3000);
			}
			else {
				$j('.close-help-btn').hide();
			}
		}
		var intervalID = window.setInterval(hideBtn, 500);
		function hideBtn() {
			if (window.zE) {
				$j('.close-help-btn').show();
				clearInterval(intervalID);
			}
		}
	} );
} )( jQuery );
