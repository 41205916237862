( function( $ ) {
	'use strict';

	$( function() {
		$j('.course-assessment-type').click(function() {
			$j(this).next().slideToggle();
			$j(this).parent().toggleClass('is-active');
		});
	} );
} )( jQuery );
