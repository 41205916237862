( function( $ ) {
	'use strict';

	$( function() {
		var $list = $( '.js-content-menu-list' );
		var $contentMenuEnabled = $( '.js-content-menu-enabled' );
		var $bespokeTrainingEnabled = $( '.tag-Professional_development' );
		var ctaSubscribe = $( '.content-menu__cta--subscribe' );
		var ctaTraining = $( '.content-menu__cta--training' );

		if ( $contentMenuEnabled.length > 0 ) {

			// Generate content menu items
			$('h2').each(function() {
				// Exclude tagline and old testimonials
				if ($(this).hasClass('course-tagline') || $(this).hasClass('page-tagline') || $(this).text() === 'What others say.') {
					return;
				}

				// Exclude hidden headings
				if ($(this).is(':hidden')) {
					return;
				}

				// Get H2 text
				var name = $(this).text();
				var id = $(this).attr('id');
				var anchor = '';

				if (id) {
					anchor = id;
				} else {
					// Create anchor by changing to lowercase and replacing spaces with dashes
					name = $.trim(name); // Remove empty spaces before/after
					anchor = name.toLowerCase().replace(/\W+/g, '-'); // Replace everything but letters and numbers with dashes
					anchor = anchor.replace(/-$/, ''); // Remove trailing dash

					// Add anchor as ID to H2
					$(this).attr('id', anchor);
				}

				// Add a link in the content menu
				$list.append('<li class="content-menu__list__item"><a class="content-menu__list__item__link" href="#' + anchor + '">' + name + '</a></li>');
			});

			// $list.append('<li class="content-menu__list__item content-menu__list__item--quote js-save-quote"><a class="content-menu__list__item__link" href="#" onclick="javascript:window.print();return false;">Print/save a quote</a></li>');

			// var linkQuote = $( '.js-save-quote' );

			// Switch out subscribe CTA for training CTA if enabled
			if ( $bespokeTrainingEnabled.length > 0 ) {
				ctaSubscribe.css('display', 'none');
				ctaTraining.css('display', 'block');
				// linkQuote.css('display', 'block');
			}
		}
	} );
} )( jQuery );
