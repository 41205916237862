( function( $ ) {
	'use strict';

	$( function() {
		var $content = $( '.course-description' );
		var $cta = $( '.js-top-cta' );

		// console.log('working');
		// console.log($cta);

		// Move cta block to top of page
		$content.prepend($cta);

	} );
} )( jQuery );
