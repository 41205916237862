( function( $ ) {
	'use strict';

	$( function() {
        var $section            = $( '.js-image-content-carousel' );
        var $carousel           = $( '.js-image-content-slider' );
        var $prevSlide          = $( '.js-image-content-slider-prev' );
        var $nextSlide          = $( '.js-image-content-slider-next' );
        var $slide              = $( '.js-image-content-slider-card' );
        var $image              = $( '.js-image-content-slider-image' );

        if ( $section.length > 0 ) {
            $carousel.slick( {
                rows: 0,
                dots: true,
                arrows: false,
                infinite: true,
                slidesToShow: 1,
                appendDots: $( '.js-image-content-carousel-dots' ),
            } );

            $prevSlide.on( 'click', function() {
                $carousel.slick( 'slickPrev' );
            } );

            $nextSlide.on( 'click', function() {
                $carousel.slick( 'slickNext' );
            } );

            function fadeAndUpdateContent() {
                $image.fadeOut( 200, function() {
                    updateSlideContent();

                    $image.fadeIn( 200 );
                } );
            }

            function updateSlideContent() {
                var $currentSlide = $carousel.find( '.slick-current' );

                if ( $currentSlide.length > 0 ) {
                    var $slideImage = $currentSlide.data( 'image' );

                    // Update the image with the image from the data of the current slide
                    $image.attr( 'src', $slideImage );
                }
            }

			fadeAndUpdateContent();

            $carousel.on( 'afterChange', function() {
                fadeAndUpdateContent();
            } );
        }
	} );
} )( jQuery );
