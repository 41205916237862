( function( $ ) {
	'use strict';

	$( function() {
		var $trigger = $( '.js-filter-mobile-dropdown' );
		var $container = $( '.js-filters-container' );

		$trigger.on( 'click', function(e) {
			e.preventDefault();
			$( this ).toggleClass( 'is-active' );
			$container.toggleClass( 'is-active' );
		} );
	} );
} )( jQuery );
