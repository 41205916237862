( function( $ ) {
	'use strict';

	$( function() {
		var pathname = location.pathname;
		var pathArray = pathname.split('/');
		var $relatedArticles = $( '.related-articles--' + pathArray[2] );

		// Show relevant related articles based on URL
		if ( $relatedArticles.length > 0 ) {
			$relatedArticles.show();
		}
	} );
} )( jQuery );
